import React from 'react'
import ReactDOM from 'react-dom'
import { withTranslation } from "react-i18next"
import { nodeList } from "../../helpers/polyfills"
import imagesLoaded from "imagesloaded"
import './genres.scss'

class Genres extends React.Component {

    setFilter(evt) {
        let filter = evt.target.getAttribute('data-filter');
        document.querySelectorAll("[data-filter]").forEach((button) => button.classList.remove("is-checked"))
        evt.target.classList.add("is-checked");
        let filterSelector = (filter !== '*') ? ".cat__" + filter : filter
        this.iso.arrange({filter: filterSelector})
    }

    getFilter() {
        return this.state.filter
    }

    componentDidMount() {
        if (typeof window !== `undefined`) {
            nodeList()
            const isotopeContainer = ReactDOM.findDOMNode(this.refs.isotopeContainer)
            imagesLoaded(isotopeContainer, () => {
                const Isotope = require('isotope-layout')
                this.iso = new Isotope(ReactDOM.findDOMNode(this.refs.isotopeContainer), {
                    itemSelector: '.gallery__item',
                    percentPosition: true,
                    transitionDuration: '0.7s',
                    layoutMode: 'masonry',
                    masonry: {
                        columnWidth: '.gallery__item',
                    }
                });    
            })
        }
    }
    
    render() {

        const { genres } = this.props
        const { t } = this.props
        const categories = []
        const AOS_DELAY = 200

        return (<div className="our-gallery-area pb-95">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title text-center" data-aos="fade-up">
                            <span>The hotel <strong>unforgettable</strong></span>
                            <h3>{t('genres')}</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="hp__portfolio__area gallery__masonry__activation ptb--110 text-center">
                            <div className="gallery__menu">
                                <button onClick={(evt) => this.setFilter(evt)} data-aos="fade-up" data-filter="*">{t('all')}</button>
                                {genres.map(({node: {frontmatter: {category}}}, index) => {
                                    if (categories.indexOf(category) < 0) {
                                        categories.push(category)
                                        let catIndex = categories.length - 1
                                        return (
                                            <button onClick={(evt) => this.setFilter(evt)} data-aos="fade-up" data-aos-delay={(index + 1) * AOS_DELAY} data-filter={catIndex}>{category}</button>
                                        )
                                    } 
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container" data-aos="fade-up">
                <div ref="isotopeContainer" className="row masonry__wrap no-gutters">
                    {genres.map(({node: {frontmatter: item}}) => (
                        <div className={"col-lg-3 col-md-6 col-sm-6 col-12 gallery__item cat__" + categories.indexOf(item.category)}>
                            <div className="portfoloi">
                                <div className="portfoloi-content">
                                    <div className="portfoloi__inner">
                                        <h2>{item.title}</h2>
                                        <p>{item.description}</p>
                                    </div>
                                </div>
                                <img src={item.image.childImageSharp.fluid.src} alt={item.alt} sizes={item.image.childImageSharp.fluid.sizes} srcset={item.image.childImageSharp.fluid.srcset} />
                            </div>
                        </div>
                    ))}
                </div> 
            </div>
        </div>)
    }
}

export default withTranslation()(Genres)